/* You can add global styles to this file, and also import other style files */

/* PrimeNG */
@import "../node_modules/primeng/resources/primeng.min.css";
@import "../node_modules/primeflex/primeflex.scss";
@import "../node_modules/primeicons/primeicons.css";
@import "assets/theme/fluent-light/theme.scss";

@import "./assets/layout/styles/layout/layout.scss";

body {
  font-family: var(--font-family);
}

::placeholder {
  color: #c4c4c4;
}

.pi-spin-always {
  -webkit-animation: fa-spin 2s infinite linear; 
  animation: fa-spin 2s infinite linear;
}

.p-datatable .p-datatable-thead > tr > th{
    border-top-width:1px;
}

/* icon was not visible with scroll in conform dialog */
.p-dialog.p-confirm-dialog .p-dialog-content{
    overflow:visible;
}

.p-toast-detail {
  white-space: pre-line;
}

.pi-rotate {
  animation: fa-spin 2s infinite linear;
}

input.disabled {
  background: var(--gray-100);
}

.tabview .p-tabview-nav {
  background-color: var(--surface-ground);
}

.min-w-300 {
  min-width: 300px;
}
.min-w-400 {
  min-width: 400px;
}
.min-w-500 {
  min-width: 500px;
}
.min-w-600 {
  min-width: 600px;
}


.max-w-400 {
  max-width: 400px;
}

.max-w-500 {
  max-width: 500px;
}

.max-w-600 {
  max-width: 600px;
}

.max-w-800 {
  max-width: 800px;
}

.max-w-1000{
    max-width: 1000px;
}


