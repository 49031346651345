/*.layout-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;*/ /* Use the full height of the viewport */
/*}*/

/*.layout-min-height {
  min-height: calc(100vh - 8rem);
}*/

/*.layout-content-wrap {
  flex: 1;
  display: flex;
}
*/
/*.layout-main-container {
  flex: 1;
  padding: 2rem;
  transition: margin-left $transitionDuration;
}

  .layout-main {
    flex: 1 1 auto;

    .card:first-child {
      border-radius: 0;
    }
  }*/

.dashboard .layout-content-wrap {
  position: relative;
  background: #0C313C;

  &::before {
    content: "";
    position: absolute;
    top: 20px;
    left: 0;
    width: 30%;
    height: 40%;
    background-image: url('/images/dashboard-cloud-one.png');
    background-position: top left;
    background-repeat: no-repeat;
    background-size: contain;
    z-index: 1;
  }

  &::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 100%;
    background-image: url('/images/dashboard-background-empty.png');
    background-position: top right;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 0;
  }

  > * {
    position: relative;
    z-index: 2;
  }

  h1 {
    color: #fff;
  }
}
